//处理图片裁剪
import { origin } from "../../public/config/request.js";
let oOrigin;
if (process.env.NODE_ENV == "development") {
  oOrigin = origin;
} else {
  oOrigin = window.location.origin;
}
function handleImg(width, height, pic, selectValue) {
  if (width && height) {
    return `http://39.103.150.135:8081/${width}x${height}/${pic}`;
  } else {
    return pic;
  }
}
function getHashParam(name) {
  try {
    if (!name) {
      return "";
    }
    if (location.hash.indexOf("?") === -1) {
      return "";
    }
    let params = location.hash.split("?")[1];
    let paramList = [];
    var param = null;
    let theRequest = {};
    if (params.length > 0) {
      if (params.indexOf("&") >= 0) {
        paramList = params.split("&");
      } else {
        paramList[0] = params;
      }
      for (var i = 0; i < paramList.length; i++) {
        theRequest[paramList[i].split("=")[0]] = paramList[i].split("=")[1];
      }
      param = theRequest[name];
    }
    return param;
  } catch (error) {
    return "";
  }
}
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  if (!window.location.hash.split("?")[1]) {
    return null;
  }
  var r = window.location.hash.split("?")[1].match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

function getOption(option, arr, attr) {
  for (var index in arr) {
    if (arr[index][attr] == option) {
      return arr[index];
    }
  }
  return {};
}

function formatStr(str) {
  return str.substring(0, 1) + new Array(str.length).join("*");
}

/** 选择框选项处理 - start */
// 通过opts生成map
const generateMapByOpts = (opts) => {
  let map = {};
  if (Array.isArray(opts)) {
    opts.forEach((item) => {
      if (item.value !== undefined) {
        map[item.value] = item.text;
      }
    });
  } else {
    throw new Error("opts must be an array");
  }
  return map;
};

// opts内加上 “请选择” 或者 “全部”
const optionsGenerator = (options) => {
  return (option) => {
    let _option = [];
    if (option) {
      if (option === 1) {
        _option.push({
          text: "全部",
          value: undefined,
        });
      } else if (option === 2) {
        _option.push({
          text: "请选择",
          value: undefined,
        });
      } else {
        _option = [options];
      }
    }
    Array.isArray(options) &&
      options.forEach((item) => {
        let tempObj = {};
        tempObj = { ...item };
        _option.push(tempObj);
      });
    return _option;
  };
};

// 选项框选项处理方法的 统一出口
const mapHelper = {
  setMap(options) {
    const map = generateMapByOpts(options);
    const setOps = optionsGenerator(options);
    return {
      map,
      setOps,
    };
  },
  setOptionGroup(list, params) {
    let { valueKey, labelKey, parentIdKey, defaultText, keys } = params;
    valueKey = valueKey || "id";
    labelKey = labelKey || "name";
    parentIdKey = parentIdKey || "parentId";

    let optionGroup = [];
    if (Array.isArray(list) && list.length) {
      let options = [];
      list.forEach((item) => {
        const parentId = item[parentIdKey];
        if ([undefined, null, ""].includes(parentId)) {
          optionGroup.push({
            label: item[labelKey],
            value: item[valueKey],
            options: [],
          });
        } else {
          options.push(item);
        }
      });
      options.forEach((option) => {
        const parentId = option[parentIdKey];
        const parentItem = optionGroup.find((item) => {
          return item.value === parentId;
        });
        const _option = {
          label: option[labelKey],
          value: option[valueKey],
        };
        if (keys && keys.length) {
          keys.forEach((key) => {
            _option[key] = option[key];
          });
        }
        parentItem.options.push(_option);
      });
    }

    if (defaultText) {
      optionGroup.unshift({
        label: defaultText,
        value: undefined,
        options: [
          {
            label: defaultText,
            value: undefined,
          },
        ],
      });
    }
    return optionGroup;
  },
};

/** 选项框选项处理 - end */
const downloadHelper = {
  downloadBySrc(config) {
    const a = document.createElement("a");
    a.href = config.src;
    if (config.target) {
      a.target = config.target;
    }
    a.download = config.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  downloadByLocation(filePath) {
    if (filePath.substring(0, 1) === "/") {
      window.location.href = `${location.origin}${location.pathname.substr(
        0,
        location.pathname.lastIndexOf("/")
      )}${filePath}`;
    } else {
      window.location.href = filePath;
    }
  },
};
function base64toFile(dataurl, filename = "file") {
  let arr = dataurl.split(",");

  let mime = arr[0].match(/:(.*?);/)[1];

  let suffix = mime.split("/")[1];

  let bstr = atob(arr[1]);

  let n = bstr.length;

  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
}

function handleSameArr(arr1, arr2) {
  arr1.push(...arr2);
  var arrset1 = new Set(arr1);
  if ([...arrset1].length == arr2.length) {
    return true;
  } else {
    return false;
  }
}
export {
  getQueryString,
  base64toFile,
  handleImg,
  getHashParam,
  getOption,
  handleSameArr,
  formatStr,
  generateMapByOpts,
  optionsGenerator,
  mapHelper,
  downloadHelper,
};
